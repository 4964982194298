import React from 'react'
import SectionGrid from '../components/styledComponents/SectionGrid'
import Link from '../components/Link'
import TitleBox from '../components/styledComponents/TitleBox'
import TopContainer from '../components/styledComponents/TopContainer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

const Page404 = () => {
  return (
    <Box sx={{ position: 'relative' }}>
      <TopContainer>
        <TitleBox>
          <Typography variant='h2'>
            We can't seem to find the page you're looking for!
          </Typography>
        </TitleBox>
        <SectionGrid container>
          <Grid item>
            <Typography variant='body1'>
              Make sure you typed the correct page address or go back to our
              HomePage
            </Typography>
          </Grid>
          <Grid item>
            <Link to='/' underline='none'>
              <Button
                variant='contained'
                color='secondary'
                disableElevation={true}
              >
                Back to Home
              </Button>
            </Link>
          </Grid>
        </SectionGrid>
      </TopContainer>
    </Box>
  )
}

export default Page404
